import { type CSSProperties } from "react";
import { HasTranslations } from ".";

export interface HasUpdatedAt {
  _updatedAt?: number;
}

export interface HasThumbnail {
  _thumbnail?: string;
  _thumbnailOutdated?: boolean;
}

export interface GameDataInterface extends HasTranslations {
  updatedAt: Date; // Database field
  version: number;

  // Game theme. TODO: Define a type
  theme?: GameTheme;

  // Screen Settings
  screen?: GameScreenSettings;

  // Game flow
  gameflow?: GameFlow;

  // Scoring configuration
  scoring?: GameScoring;

  // Timer configuration
  timer?: GameTimer;

  // Game Levels
  levels?: { [levelId: string]: GameLayerContent };
  levelOrder?: string[];

  // Game UI Overlay
  overlays?: { [overlayId: string]: GameLayerContent };

  // Game clues
  clues?: { [clueId: string]: GameClue };

  // Game items
  items?: { [itemId: string]: GameItem };
}

export type GameActionTrigger =
  | "click"
  | "completion"
  | "solve"
  | "success"
  | "fail"
  | "enter"
  | "leave"
  | "legacy";

export interface GameAction {
  command: string;
  payload?: string;
}

export type GameActionsMap = Partial<Record<GameActionTrigger, GameAction[]>>;

export interface Transition {
  name?: string;
  duration?: number;
  delay?: number;
  easing?: string;
  loop?: boolean;
}

export interface HasLayout {
  layout: GameElement<Record<string, unknown>>[];
}

// string[] and string are legacy actions (still working)
export interface HasActions {
  actions?: string[] | string | GameActionsMap;
  actionsFail?: string[] | string;
}

export interface GameLayerContent
  extends HasLayout,
    HasTranslations,
    HasActions,
    HasUpdatedAt,
    HasThumbnail {
  style?: CSSProperties;
  transitions?: TransitionSettings;
  overlay?: string;
}

export interface GameClue extends HasTranslations, HasUpdatedAt {
  score?: number;
}

export interface GameItem extends HasTranslations, HasUpdatedAt {
  thumbnailAssetName: string;
  fullsizeAssetImageName?: string;
  fullsizeAssetVideoName?: string;
  fullsizeAssetAudioName?: string;
  fullsizeAssetDownloadName?: string;
}

export interface GameElementStageProps extends HasUpdatedAt {
  x: number;
  y: number;
  width: number | null;
  height: number | null;
  originX: number;
  originY: number;
  rotation: number;
  scale: number;
  opacity: number;
}

export enum GameElementCategory {
  // General elements. No specific behavior.
  // They may support an action on a single trigger (click, completion, etc.)
  STATIC = "static",

  // Locks. Require the right solution to obtain the key.
  // Can have success and fail action triggers.
  LOCK = "lock",

  // Minigames. Doesn't require a solution but the player must complete it
  // in order to trigger the action. The minigame can have a success and
  // fail actions triggers
  MINIGAME = "minigame",

  // Items. Can be collected by the player. Legacy. Remove?
  ITEM = "item",

  // Special objects for the editor. Not rendered in the game.
  HELPER = "helper",

  // UI Specific elements: Gamification, HUD, etc.
  UI = "ui",
}

export interface GameElement<Props = Record<string, unknown>>
  extends Partial<HasLayout>,
    HasActions,
    Partial<HasTranslations>,
    HasUpdatedAt {
  _id?: string; // original id
  id: string; // uuid
  name: string;
  type: GameElementCategory;
  component: string; // component id or <name>
  stage: GameElementStageProps; // stage properties
  properties: Props; // component specific properties
  transitions?: TransitionSettings;
  style?: CSSProperties;
  rawStyle?: string; // Todo: Deprecate this?

  /*
   * Private properties.
   */

  enabled: boolean;
  // the element requires updates in every game state change
  update?: "always";
  condition?: string;

  // lock/item specific properties
  itemId?: string;
  lockId?: string;
  solutions?: string[];
}

// GameElementComponentProps is a placeholder for the component specific properties
export type GameElementComponentProps = Record<string, unknown>;

export interface TransitionSettings extends HasUpdatedAt {
  in?: Transition;
  out?: Transition;
}

export interface GameScreenSettings extends HasUpdatedAt {
  width: number;
  height: number;
  orientation?: "landscape" | "portrait";
  fullScreen?: boolean;
}

export interface GameTheme extends HasUpdatedAt {
  background?: string;
  text?: string;
}

export interface GameTimer extends HasUpdatedAt {
  startLevelId?: string;
  duration?: number; // in minutes
}

export interface GameScoring extends HasUpdatedAt {
  requestClue: number;
  collectItem: number;
  unlockSuccess: number;
  unlockFailed: number;
  endSecondsLeft: number;
  completeGame: number;
}

export type GameScoreType = keyof GameScoring;

export interface GameFlow extends HasUpdatedAt {
  initialLevelId: string;
  finishLevelId: string;
  gameOverLevelId: string;
}
