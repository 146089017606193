export enum GameErrorCode {
  // game definition errors
  GAME_UNDEFINED_ERROR = 0,
  GAME_IN_PROGRESS_NOT_FOUND = 1,
  GAME_ALREADY_IN_PROGRESS = 2,
  GAME_BAD_DEFINITION = 3,
  UNKNOWN_GAME_ACTION = 5,
  UNEXPECTED_ANSWER_TYPE = 6,
  INVALID_EXPRESSION = 7,

  // in-game critical errors
  LEVEL_ACCESS_DENIED = 10,
  LEVEL_NOT_FOUND = 11,
  GAME_ELEMENT_NOT_FOUND = 12,
  CLUE_NOT_FOUND = 14,
  ITEM_NOT_FOUND = 15,
  MISSING_PAYLOAD = 16,
  OVERLAY_NOT_FOUND = 17,
}
