import { ApiRestClient } from "../ApiRest";

import { GameAssetDto, GameDefinitionDto } from "../dtos";

type Routes = {
  "/g/:uuid": GameDefinitionDto;

  // Frontend driven game engine
  "/g/:uuid/data": { d: string }; // The whole game data encrypted
  "/g/:uuid/assets": GameAssetDto;

  // Backend driven game engine (TBD). These are the rendered levels
  "/g/:uuid/d": { d: string };
  "/g/:uuid/d/l/:levelId": { d: string };
  "/g/:uuid/d/l/:levelId/a": GameAssetDto;
  "/g/:uuid/d/l/:levelId/e/:elementId": { d: string; a: string }; // updates + actions
};

export class PlayerApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/");
  }
}
