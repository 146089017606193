export class ApiRestError extends Error {
  public response: Response;
  public status?: number;
  public requestId?: string;

  constructor(response: Response, message: string) {
    super(message);
    this.response = response;
    this.status = response.status;
    this.requestId = response.headers.get("x-request-id") || undefined;
  }
}
