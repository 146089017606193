import { useContext, useEffect, useState } from "react";
import type { PluginManifest } from "@shared/utils/plugins";

import { ComponentContext } from "../ComponentProvider";

export function usePluginManifest(id?: string) {
  const {
    plugins: { getPluginManifest },
  } = useContext(ComponentContext);

  // Manifest of the plugin when a single element is selected
  const [manifest, setManifest] =
    useState<PluginManifest<Record<string, unknown>>>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setError(undefined);
    setLoading(true);

    if (id) {
      getPluginManifest(id)
        .then((manifest) => {
          if (!manifest) {
            setError(new Error(`Plugin with id ${id} not found`));
          }

          setManifest(manifest || undefined);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setManifest(undefined);
          setLoading(false);
        });
    } else {
      setManifest(undefined);
      setLoading(false);
    }
  }, [getPluginManifest, id]);

  return { manifest, loading, error };
}
