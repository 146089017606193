import { type FallbackProps } from "react-error-boundary";

import UfitoImg from "@shared/branding/imagery/art/ufito-cientifico-1.png";

export default function ErrorPage(props: FallbackProps) {
  const message = props.error?.message || "An error occurred";

  return (
    <div className="ufo-error-page">
      <img src={UfitoImg} height="200" />
      <h2>Something went wrong</h2>
      <p>{message}</p>
    </div>
  );
}
