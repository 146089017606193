import { createContext, useCallback, useMemo, useState } from "react";
import url from "url";

import { createLogger } from "@shared/utils/logging";
import {
  type PluginManifest,
  type PluginBaseProps,
  validateComponentManifest,
  ManifestError,
} from "@shared/utils/plugins";
import { MANIFEST_FILE } from "@shared/utils/constants";
import { type PluginComponent } from "./types/GameComponent";

const { info: logInfo, error: logError } = createLogger("PluginManager");

// Random number for avoiding cache loading plugins
const randomNumber = Math.random().toString(36).substring(7);

const pluginCache = new Map<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PluginComponent<PluginBaseProps<any>>
>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const manifestCache = new Map<string, PluginManifest<any>>();

async function loadPluginComponent<P>(
  pluginName: string,
  baseUrl: string,
  pluginIndex = "index.tsx",
  version: string = randomNumber,
  forceLoad = false
): Promise<PluginComponent<PluginBaseProps<P>> | null> {
  // Load the plugin
  if (!forceLoad && pluginCache.has(pluginName)) {
    return pluginCache.get(pluginName) as unknown as PluginComponent<
      PluginBaseProps<P>
    >;
  }

  let fullUrl = [
    baseUrl?.endsWith("/") ? baseUrl : baseUrl + "/",
    "game-component/",
    pluginName + "/",
    pluginIndex + (version ? `?v=${version}` : ""),
  ].reduce(url.resolve);

  try {
    logInfo("Loading plugin", pluginName);

    // Fix the fullURL for relative imports
    // TODO: This is not nice. Rethink and refactor
    if (!fullUrl.startsWith("http")) {
      fullUrl = url.resolve(document.location.href, fullUrl);
    }

    const plugin = (await import(/* @vite-ignore */ fullUrl))
      .default as PluginComponent<PluginBaseProps<P>>;

    if (!plugin) {
      throw new Error(`Plugin ${pluginName} not found`);
    }

    pluginCache.set(pluginName, plugin);
    return plugin as PluginComponent<PluginBaseProps<P>>;
  } catch (error) {
    logError(`Failed to load plugin ${pluginName} (${fullUrl}). ${error}`);
    return null;
  }
}

async function loadPluginManifest<P>(
  pluginName: string,
  baseUrl: string,
  version: string = randomNumber,
  forceLoad = false
): Promise<PluginManifest<P> | null> {
  // Load the plugin
  if (!forceLoad && manifestCache.has(pluginName)) {
    return manifestCache.get(pluginName)!;
  }

  const fullUrl = url.resolve(
    baseUrl + "/game-component/" + pluginName + "/",
    MANIFEST_FILE + (version ? `?v=${version}` : "")
  );

  try {
    logInfo("Loading plugin metadata", pluginName);
    const manifest = await fetch(fullUrl).then((res) => res.json());
    const validManifest = validateComponentManifest<P>(manifest);

    manifestCache.set(pluginName, validManifest);
    return validManifest;
  } catch (error) {
    logError(
      `Failed to load plugin metadata ${pluginName} (${fullUrl}). ${error}`
    );

    if (error instanceof ManifestError) {
      logError("Manifest errors:", error.errors);
    }

    return null;
  }
}

interface SoundController {
  mute: boolean;
  volume: number;
  setMute: (mute: boolean) => void;
  setVolume: (volume: number) => void;
}

interface PluginManager {
  getPluginComponent: <P = Record<string, unknown>>(
    pluginName: string,
    version?: string
  ) => Promise<PluginComponent<PluginBaseProps<P>> | null>;
  getPluginManifest: <P = Record<string, unknown>>(
    pluginName: string,
    version?: string
  ) => Promise<PluginManifest<P> | null>;
  getCachedPluginManifest: <P = Record<string, unknown>>(
    pluginName: string
  ) => PluginManifest<P> | null;
}

interface ComponentContextState {
  sound: SoundController;
  plugins: PluginManager;
  pluginsBaseUrl: string;
  builtinPluginsBaseUrl: string;
}

interface ComponentProviderProps {
  builtinPluginsBaseUrl: string;
  pluginsBaseUrl: string;
  indexFile: string;
  children: React.ReactNode;
}

export const ComponentContext = createContext<ComponentContextState>({
  sound: { mute: false, volume: 1, setMute: () => {}, setVolume: () => {} },
  plugins: {
    getPluginComponent: () => {
      logError("Required context provided not loaded!");
      return Promise.reject();
    },
    getPluginManifest: () => {
      logError("Required context provided not loaded!");
      return Promise.reject();
    },
    getCachedPluginManifest: () => {
      logError("Required context provided not loaded!");
      return null;
    },
  },
  pluginsBaseUrl: "",
  builtinPluginsBaseUrl: "",
});

export function ComponentProvider({
  children,
  builtinPluginsBaseUrl,
  pluginsBaseUrl,
  indexFile,
}: ComponentProviderProps) {
  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(1);

  /**
   * Get plugin component
   *
   * @param pluginName Plugin name
   * @returns Plugin component
   */
  const getPluginComponent = useCallback(
    <P,>(pluginName: string, version?: string) => {
      // builtin plugins; <plugin-name>
      if (pluginName.match(/^<[a-z0-9-]+>$/)) {
        return loadPluginComponent<P>(
          pluginName.replace(/^<|>$/g, ""),
          builtinPluginsBaseUrl,
          indexFile
        );
      }

      return loadPluginComponent<P>(
        pluginName,
        pluginsBaseUrl,
        "index.js" /* Fixed because always external */,
        version
      );
    },
    [builtinPluginsBaseUrl, pluginsBaseUrl, indexFile]
  );

  /**
   * Load plugin metadata (manifest.json)
   *
   * @param pluginName Plugin name
   * @returns Plugin metadata
   */
  const getPluginManifest = useCallback(
    async <P,>(pluginName: string, version?: string) => {
      if (pluginName.match(/^<[a-z0-9-]+>$/)) {
        return loadPluginManifest<P>(
          pluginName.replace(/^<|>$/g, ""),
          builtinPluginsBaseUrl,
          version
        );
      }

      return loadPluginManifest<P>(pluginName, pluginsBaseUrl, version);
    },
    [builtinPluginsBaseUrl, pluginsBaseUrl]
  );

  /**
   * Get cached plugin metadata, synchronous
   */
  const getCachedPluginManifest = useCallback(<P,>(pluginName: string) => {
    if (pluginName.match(/^<[a-z0-9-]+>$/)) {
      return manifestCache.get(
        pluginName.replace(/^<|>$/g, "")
      ) as PluginManifest<P>;
    }

    return manifestCache.get(pluginName) as PluginManifest<P>;
  }, []);

  const providerProps = useMemo(
    () => ({
      sound: { mute, volume, setMute, setVolume },
      plugins: {
        getPluginComponent,
        getPluginManifest,
        getCachedPluginManifest,
      },
      pluginsBaseUrl,
      builtinPluginsBaseUrl,
    }),
    [
      mute,
      volume,
      setMute,
      setVolume,
      getPluginComponent,
      getPluginManifest,
      getCachedPluginManifest,
      pluginsBaseUrl,
      builtinPluginsBaseUrl,
    ]
  );

  return (
    <ComponentContext.Provider value={providerProps}>
      {children}
    </ComponentContext.Provider>
  );
}
