interface TransitionDefinition {
  name: string;
  keyframes: {
    [keyframe: string]: {
      [property: string]: string | number;
    };
  };
  duration: number;
  delay: number;
  easing: string;
  repeat: number;
}

export interface TransitionAnimation {
  in: TransitionDefinition;
  out: TransitionDefinition;
}

export interface TransitionRepository {
  [name: string]: TransitionAnimation;
}

// Keyframes definition for transitions
export const transitions: TransitionRepository = {
  fade: {
    in: {
      name: "Fundido",
      keyframes: {
        from: {
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
    out: {
      name: "Fundido",
      keyframes: {
        from: {
          opacity: 1,
          visibility: "visible",
        },
        to: {
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
  },

  slideLeft: {
    in: {
      name: "Deslizar desde la izquierda",
      keyframes: {
        from: {
          transform: "translateX(-100%)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "translateX(0)",
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-out",
      repeat: 0,
    },
    out: {
      name: "Deslizar hacia la izquierda",
      keyframes: {
        from: {
          transform: "translateX(0)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "translateX(-100%)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-in",
      repeat: 0,
    },
  },

  slideRight: {
    in: {
      name: "Deslizar desde la derecha",
      keyframes: {
        from: {
          transform: "translateX(100%)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "translateX(0)",
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-out",
      repeat: 0,
    },
    out: {
      name: "Deslizar hacia la derecha",
      keyframes: {
        from: {
          transform: "translateX(0)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "translateX(100%)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-in",
      repeat: 0,
    },
  },

  slideUp: {
    in: {
      name: "Deslizar desde arriba",
      keyframes: {
        from: {
          transform: "translateY(-100%)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "translateY(0)",
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-out",
      repeat: 0,
    },
    out: {
      name: "Deslizar hacia arriba",
      keyframes: {
        from: {
          transform: "translateY(0)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "translateY(-100%)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-in",
      repeat: 0,
    },
  },

  slideDown: {
    in: {
      name: "Deslizar desde abajo",
      keyframes: {
        from: {
          transform: "translateY(100%)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "translateY(0)",
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-out",
      repeat: 0,
    },
    out: {
      name: "Deslizar hacia abajo",
      keyframes: {
        from: {
          transform: "translateY(0)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "translateY(100%)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease-in",
      repeat: 0,
    },
  },

  zoomIn: {
    in: {
      name: "Acercar",
      keyframes: {
        from: {
          transform: "scale(0.5)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "scale(1)",
          opacity: 1,
          visibility: "visible",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
    out: {
      name: "Alejar",
      keyframes: {
        from: {
          transform: "scale(1)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "scale(0.5)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
  },

  zoomOut: {
    in: {
      name: "Alejar",
      keyframes: {
        from: {
          transform: "scale(2)",
          opacity: 0,
          visibility: "hidden",
        },
        to: {
          transform: "scale(1)",
          opacity: 1,
          visibility: "visibile",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
    out: {
      name: "Acercar",
      keyframes: {
        from: {
          transform: "scale(1)",
          opacity: 1,
          visibility: "visible",
        },
        to: {
          transform: "scale(2)",
          opacity: 0,
          visibility: "hidden",
        },
      },
      duration: 500,
      delay: 0,
      easing: "ease",
      repeat: 0,
    },
  },
};

/**
 * Convert a transition definition to CSS keyframes
 *
 * @param name
 * @param transition
 * @returns
 */
function getKeyframes(transition: TransitionDefinition) {
  return Object.entries(transition.keyframes).map(([keyframe, properties]) => {
    const cssProperties = Object.entries(properties)
      .map(([property, value]) => `${property}: ${value};`)
      .join("\n");

    return `${keyframe} {
        ${cssProperties}
      }`;
  });
}

/**
 * Convert all transitions to CSS keyframes
 */
const cssKeyframes = Object.entries(transitions).reduce<string[]>(
  (out, [name, transition]) => [
    ...out,
    `@keyframes ${name}-in { ${getKeyframes(transition.in).join("\n")} }`,
    `@keyframes ${name}-out { ${getKeyframes(transition.out).join("\n")} }`,
  ],
  []
);

/**
 * Create a style tag with all animation keyframes
 */
export function createAnimationsStyleTag() {
  const style = document.createElement("style");
  style.innerHTML = Object.values(cssKeyframes).join("\n");
  document.head.appendChild(style);
}
