import { type GameAction, GameCommand } from "@shared/game-engine";

/**
 * Frontend action executor
 *
 * @param action
 * @param payload
 */
export function executeAction(action: GameAction) {
  switch (action.command) {
    case GameCommand.VISIT_URL: {
      let target = "_blank";
      let url: string = "";

      const [maybeTarget, maybeURL] = action.payload?.split("|") || [];

      if (!maybeURL) {
        url = maybeTarget;
      } else {
        target = maybeTarget;
        url = maybeURL;
      }

      if (!url) {
        return;
      }

      const formattedUrl = url.startsWith("http")
        ? action.payload
        : `http://${action.payload}`;

      window.open(formattedUrl, target);
      break;
    }
  }
}
