import { useState, useEffect, type ReactNode } from "react";

interface Props {
  enabled?: boolean;
  opaque?: boolean;
  children: React.ReactElement;
  warning?: (playCallback: () => void) => ReactNode;
}

// This is a hack to check if the browser has blocked autoplay in media elements.
const check = async () => {
  try {
    await new Audio(
      "data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQAAAAA="
    ).play();
    return true;
  } catch (e) {
    return false;
  }
};

export const RequireInteractionBoundary = ({
  enabled = true,
  children,
  warning,
}: Props) => {
  const [canPlay, setCanPlay] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    check().then(setCanPlay);
  }, []);

  if (enabled && canPlay === false) {
    return warning ? (
      warning(() => setCanPlay(true))
    ) : (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(0, 0, 0, 0.8)",
          color: "white",
          zIndex: 9999,
        }}
      >
        <div onClick={() => setCanPlay(true)}>
          <h1>Click to start</h1>
          <p>Click anywhere on the screen to start the game</p>
        </div>
      </div>
    );
  }

  if (!enabled || canPlay === true) {
    return children;
  }

  // waiting state
  return null;
};
