export interface Logger {
  info: (message: string, ...args: unknown[]) => void;
  warn: (message: string, ...args: unknown[]) => void;
  error: (message: string, ...args: unknown[]) => void;
  debug: (message: string, ...args: unknown[]) => void;
}

interface LoggerOptions {
  canLog?: (errorLevel: number, message: unknown) => boolean;
  subtags?: string[];
}

/**
 * Create a basic logger that uses built-in console methods
 *
 * @param name
 * @returns
 */
export function createLogger(name: string, options?: LoggerOptions): Logger {
  const {
    canLog = (errorLevel: number): boolean => errorLevel <= 1,
    subtags = [],
  } = options || {};

  const prefix =
    subtags.length > 0 ? `[${name}] (${subtags.join("|")})` : `[${name}]`;

  return {
    error: (message: unknown, ...args) =>
      canLog(1, message) && console.error(`${prefix} ${message}`, ...args),
    warn: (message: unknown, ...args) =>
      canLog(2, message) && console.warn(`${prefix} ${message}`, ...args),
    info: (message: unknown, ...args) =>
      canLog(3, message) && console.log(`${prefix} ${message}`, ...args),
    debug: (message: unknown, ...args) =>
      canLog(4, message) && console.debug(`${prefix} ${message}`, ...args),
  };
}
