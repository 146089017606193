import { type FallbackProps } from "react-error-boundary";

export function ErrorComponent({
  componentName,
  error,
}: {
  componentName?: string;
  error?: FallbackProps;
}) {
  console.error("Error in component:", componentName, error?.error);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        border: "1px dashed rgba(255, 0, 0, 0.3)",
        textAlign: "center",
      }}
    >
      Error!
      {(error?.error && String(error?.error)) ||
        `Failed to load component ${componentName}`}
    </div>
  );
}
