import {
  type CSSProperties,
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useCallback,
} from "react";

import {
  GameElementCategory,
  type GameStateInterface,
  type GameElementRendered,
  type GameActionTrigger,
} from "@shared/game-engine";

import {
  getTransforms,
  styleObjectToString,
  transitionObjectToCSSAnimation,
} from "../utils/styles";
import { ComponentContext } from "../ComponentProvider";
import { type PluginComponent } from "../types/GameComponent";
import { PlaceholderComponent } from "./PlaceholderComponent";
import { ErrorComponent } from "./ErrorComponent";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";

interface Props {
  element: GameElementRendered;
  onAction?: (id: string, trigger: GameActionTrigger, payload?: string) => void;
  gameState?: GameStateInterface;
  noTransitions?: boolean;
  editorMode?: boolean;
}

export const StageElement = ({
  onAction,
  element,
  gameState,
  noTransitions,
  editorMode,
}: Props) => {
  const {
    id,
    type,
    component,
    enabled = true,
    style = {},
    rawStyle,
    actionable = false,
    stage,
    transitions,
  } = element;
  const [loaded, setLoaded] = useState(false);
  const [finalStyleString, setFinalStyleString] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [Component, setComponent] = useState<PluginComponent<any> | null>(
    () => PlaceholderComponent
  );

  // Workaround to skip animations on first render when disabled
  const [skipAnimation, setSkipAnimation] = useState(!enabled);

  const {
    plugins: { getPluginComponent },
  } = useContext(ComponentContext);

  const classes = ["stage-element"];

  const elementId = `el-${id}`;
  const cssSelector = `#${elementId}`;

  // Try to skip animations on first render, for those elements
  // hidden from their initial state.
  // Not great. TODO: Find a better way to do this.
  useLayoutEffect(() => {
    if (loaded) {
      setTimeout(() => setSkipAnimation(false), 1000);
    }
  }, [loaded]);

  // Process style object
  useEffect(() => {
    const positioningStyle: CSSProperties = {
      position: "absolute",
      boxSizing: "border-box",

      ...getTransforms(stage, style),
      opacity: stage?.opacity !== undefined ? stage.opacity : 1,
    };

    const stateStyle: CSSProperties = {};

    // Static components doesn't have mouse interactions unless actionable
    // or explicitly enabled inside
    const isStaticComponent = type === GameElementCategory.STATIC;

    if (!enabled || (isStaticComponent && !actionable)) {
      // Disable pointer events and set opacity to 0
      stateStyle.pointerEvents = "none";
      stateStyle.cursor = "not-allowed";
      stateStyle.opacity = 0;
    } else {
      stateStyle.pointerEvents = "auto";
    }

    // Positioning and state styles only
    const inlineStyles = styleObjectToString({
      ...stateStyle,
      ...positioningStyle,
    });

    // Transitions styles
    const animationType = enabled ? "in" : "out";
    const transition = !noTransitions && transitions?.[animationType];
    const transitionsStyle = transition
      ? transitionObjectToCSSAnimation(transition, animationType)
      : {};

    // Inner styles: custom styles, animations, transitions, etc
    const inlineStylesInner = styleObjectToString({
      ...transitionsStyle,
      ...style,
      position: "relative",
      width: "100%",
      height: "100%",

      // Speed up animations on first render
      ...(skipAnimation && !noTransitions
        ? {
            animationDuration: "0s",
          }
        : {}),
    });

    const inlineStylesString = inlineStyles
      ? `${cssSelector} {${inlineStyles}} ${cssSelector} > .in {${inlineStylesInner}`
      : "";

    // Process rawStyle string. ".class" will become the right css selector and all them together
    const rawStylesString =
      rawStyle?.trim() && rawStyle.replace(/\.class\b/g, cssSelector);

    const fullStylesString = [inlineStylesString, rawStylesString]
      .filter((v) => !!v?.trim())
      .join("\n");

    setFinalStyleString(fullStylesString);
  }, [
    style,
    rawStyle,
    cssSelector,
    enabled,
    stage,
    transitions,
    noTransitions,
    skipAnimation,
    type,
    actionable,
  ]);

  useEffect(() => {
    if (component) {
      getPluginComponent(component).then((component) => {
        setComponent(() => component);
        setLoaded(true);
      });
    }
  }, [component, getPluginComponent]);

  // V1 actions
  const onActionHandler = useCallback(
    (payload?: string) => {
      onAction?.(id, "legacy", payload);
    },
    [id, onAction]
  );

  // V2 actions
  const doActionHandler = useCallback(
    (trigger: GameActionTrigger, payload?: string) => {
      onAction?.(id, trigger, payload);
    },
    [id, onAction]
  );

  const fallbackComponentHandler = useCallback(
    (error: FallbackProps) => (
      <ErrorComponent componentName={element.component} error={error} />
    ),
    [element?.component]
  );

  if (type === GameElementCategory.HELPER) {
    // Helper elements are not rendered
    return null;
  }

  if (!enabled) {
    classes.push("element-disabled");
  }

  return (
    <>
      {finalStyleString && <style>{finalStyleString}</style>}
      <div id={elementId} className={classes.join(" ")}>
        <div className="in">
          <ErrorBoundary fallbackRender={fallbackComponentHandler}>
            {Component ? (
              <Component
                {...element}
                onAction={onActionHandler}
                doAction={doActionHandler}
                gameState={gameState}
                __editor__={editorMode}
              />
            ) : (
              <ErrorComponent componentName={element.component} />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
