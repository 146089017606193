import type { CSSProperties } from "react";
import type { Transition, GameElementStageProps } from "@shared/game-engine";

/**
 * Normalize a measure value to a valid CSS value
 *
 * @param value
 * @returns
 */
export const normalizeMeasure = (value: string | number) => {
  // valid units
  const validUnitsRegex =
    /(px|cm|mm|in|pt|pc|%|em|ex|ch|rem|vw|vh|vmin|vmax)$/i;

  try {
    if (!value.toString().trim().match(validUnitsRegex)) {
      // add px as default unit
      return `${parseFloat(
        value
          .toString()
          .trim()
          .replace(/[^0-9.-]/, "")
      )}px`;
    }
  } catch (err) {
    // ignore
  }

  return value.toString().trim();
};

/**
 * Generate a CSS string from the given style object
 *
 * @param style
 * @returns
 */
export const styleObjectToString = (style: Record<string, unknown>): string => {
  const styleKeys = Object.keys(style);

  return styleKeys
    .filter((key) => !!style[key]?.toString().trim())
    .reduce(
      (prev, curr) =>
        `${(prev += curr
          .split(/(?=[A-Z])/)
          .join("-")
          .toLowerCase())}:${style[curr]};`,
      ""
    );
};

export const transitionObjectToCSSAnimation = (
  transition: Transition = {},
  animation?: "in" | "out"
): CSSProperties => {
  if (!transition.name) {
    return {};
  }

  return {
    animationName: `${transition.name}${animation ? "-" + animation : ""}`,
    animationDuration: `${transition.duration || 0}s`,
    animationDelay: `${transition.delay || 0}s`,
    animationTimingFunction: transition.easing || "linear",
    animationIterationCount: transition.loop ? "infinite" : "1",
    animationFillMode: "both",
  };
};

/**
 * Get the CSS transforms for the given stage
 *
 * @param stage
 * @param style
 * @returns
 */
export const getTransforms = (
  stage: GameElementStageProps,
  style: CSSProperties = {}
): CSSProperties => {
  const transforms = style.transform?.trim().split(" ") || [];

  const {
    x = 0,
    y = 0,
    originX = 0.5,
    originY = 0.5,
    rotation,
    scale,
    width,
    height,
  } = stage;

  // position
  transforms.push(`translate(${normalizeMeasure(x)}, ${normalizeMeasure(y)})`);

  if (rotation !== undefined) {
    transforms.push(`rotate(${Number(rotation)}deg)`);
  }

  if (scale !== undefined) {
    transforms.push(`scale(${Number(scale)})`);
  }

  return {
    transformOrigin: stage && `${originX * 100}% ${originY * 100}%`,
    transform: transforms.join(" "),
    width: width !== null ? normalizeMeasure(width) : undefined,
    height: height !== null ? normalizeMeasure(height) : undefined,
  };
};

/**
 * Apply scale transforms to the given stage
 *
 * @param stage
 * @param scale
 * @param offset
 * @returns
 */
export const scaleTransforms = (
  stage: GameElementStageProps,
  scale: number,
  offset: { x: number; y: number } = { x: 0, y: 0 }
): GameElementStageProps => {
  return {
    ...stage,
    x: offset.x + stage.x * scale,
    y: offset.y + stage.y * scale,
    width: stage.width !== null ? stage.width * scale : null,
    height: stage.height !== null ? stage.height * scale : null,
  };
};

/**
 * Remove scale transforms from the given stage
 *
 * @param stage
 * @param scale
 * @param offset
 * @returns
 */
export const unscaleTransforms = (
  stage: GameElementStageProps,
  scale: number,
  offset: { x: number; y: number } = { x: 0, y: 0 }
): GameElementStageProps => {
  return {
    ...stage,
    x: (stage.x - offset.x) / scale,
    y: (stage.y - offset.y) / scale,
    width: stage.width !== null ? stage.width / scale : null,
    height: stage.height !== null ? stage.height / scale : null,
  };
};
