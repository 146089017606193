import React, { createContext } from "react";

import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Define the shape of your context value
export interface FirebaseContextValue {
  logEvent?: (event: string, data: Record<string, unknown>) => void;
}

// Create the context
export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined
);

interface Props {
  config: FirebaseOptions;
  children: React.ReactNode;
}

// Create a provider component
export const FirebaseProvider = ({ children, config }: Props) => {
  const app = config.apiKey ? initializeApp(config) : undefined;
  const analytics = app && getAnalytics(app);

  const value: FirebaseContextValue = {
    logEvent: (event: string, data: Record<string, unknown>) => {
      if (analytics) {
        logEvent(analytics, event, data);
      } else {
        console.debug("[Firebase] logEvent", event, data);
      }
    },
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
