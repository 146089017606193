import { type ReactNode, useEffect, useState } from "react";
import {
  FullScreen,
  type FullScreenProps,
  useFullScreenHandle,
} from "react-full-screen";
import fscreen from "fscreen";

type Orientation = "landscape" | "portrait";

interface Props {
  children: ReactNode;
  requireOrientation?: Orientation | undefined;
  requireFullScreen?: boolean;
  fullScreenWarning?: (doFullScreen: () => void) => ReactNode;
  orientationWarning?: ReactNode;
}

function getDeviceOrientation(): Orientation {
  if (window.screen.orientation) {
    return window.screen.orientation.type.includes("landscape")
      ? "landscape"
      : "portrait";
  }

  // iOS/safari
  return Math.abs(+window.orientation) === 90 ? "landscape" : "portrait";
}

const isOrientation = (orientation: Orientation) =>
  getDeviceOrientation() === orientation;

// User agent detection
const isDesktop = () =>
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const isFullScreenSupported = () => !!fscreen.fullscreenEnabled;

const isOrientationSupported = () =>
  !isDesktop() && !!window.screen.orientation;

const switchLockOrientation = (status: boolean, orientation: Orientation) => {
  // Not supported everywhere
  if (window.screen.orientation) {
    try {
      if (status) {
        // @ts-expect-error: lock is not in the types
        window.screen.orientation.lock(orientation);
      } else {
        window.screen.orientation.unlock();
      }
    } catch (e) {
      // Unsupported. Do nothing
    }
  }
};

interface FSWrapperProps extends React.PropsWithChildren<FullScreenProps> {}

const FSWrapper = ({
  children,
  className,
  handle,
  ...props
}: FSWrapperProps) =>
  handle ? (
    <FullScreen handle={handle} {...props}>
      {children}
    </FullScreen>
  ) : (
    <div className={`fullscreen fullscreen-unsupported ${className || ""}`}>
      {children}
    </div>
  );

export const FullScreenBoundary = ({
  children,
  requireFullScreen,
  requireOrientation,
  fullScreenWarning,
  orientationWarning,
}: Props) => {
  const [fsWarning, setFsWarning] = useState(
    isFullScreenSupported() && requireFullScreen
  );
  const [showOrientationWarning, setShowOrientationWarning] = useState(
    isOrientationSupported() && requireOrientation
      ? !isOrientation(requireOrientation)
      : false
  );

  // Here determines if we support full screen
  const fsHandle = useFullScreenHandle();

  useEffect(() => {
    setFsWarning(!fsHandle?.active);
  }, [fsHandle?.active]);

  useEffect(() => {
    // Orientation change listener
    const refreshOrientation = () => {
      if (!requireOrientation || !isOrientationSupported()) return;
      setShowOrientationWarning(!isOrientation(requireOrientation));
    };

    // Listen a custom event for devices that doesn't support the FullScreen API
    const fakeExitFullScreen = () => setFsWarning(requireFullScreen);

    document.addEventListener("fake-exitfullscreen", fakeExitFullScreen);
    window.addEventListener("orientationchange", refreshOrientation);
    refreshOrientation();

    return () => {
      window.removeEventListener("fake-exitfullscreen", fakeExitFullScreen);
      window.removeEventListener("orientationchange", refreshOrientation);
    };
  }, [requireFullScreen, requireOrientation]);

  const doFullScreen = async () => {
    if (!requireFullScreen) return;

    if (!isFullScreenSupported()) {
      setFsWarning(() => false);
    }

    if (fsHandle) {
      await fsHandle.enter();
      // dispatch resize so the player rescales
      window.dispatchEvent(new Event("resize"));
    } else {
      setFsWarning(() => false);
    }
  };

  return (
    <>
      {requireFullScreen && fsWarning
        ? fullScreenWarning?.(doFullScreen)
        : null}

      <FSWrapper
        handle={fsHandle!}
        onChange={(enabled) =>
          requireOrientation &&
          switchLockOrientation(enabled, requireOrientation)
        }
        className={!fsWarning ? "fullscreen-enabled" : ""} // This is for the fake full screen
      >
        {requireOrientation && showOrientationWarning && !fsWarning
          ? orientationWarning
          : null}
        {children}
      </FSWrapper>
    </>
  );
};
