import { validate as validateJsonSchema } from "json-schema";

import { GameElementCategory } from "@shared/game-engine";

import { PluginManifest } from "./types/manifest";
import { ManifestError } from "./types/ManifestError";

export function validateComponentManifest<P>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): PluginManifest<P> {
  const errors: string[] = [];

  if (data.type !== "game-component") {
    throw new Error(
      `Invalid package type ${data.type}. Must be 'game-component'`
    );
  }

  if (
    !data.category ||
    !Object.values(GameElementCategory).includes(data.category)
  ) {
    throw new Error(
      `Invalid category ${data.category}. Must be one of ${Object.values(GameElementCategory).join(", ")}`
    );
  }

  const requiredFields: (keyof PluginManifest<P>)[] = [
    "name",
    "description",
    "version",
    "thumbnail",
    "schema",
    "features",
    "templates",
  ];

  requiredFields.forEach((key: keyof PluginManifest<P>) => {
    if (!data[key]) {
      errors.push(`Missing "${key}"`);
    }
  });

  if (typeof data.version !== "number") {
    errors.push("Version must be a number");
  }

  const schemaResult = validateJsonSchema({}, data.schema);

  if (!schemaResult.valid) {
    errors.push(
      "Invalid schema: " + schemaResult.errors.map((e) => e.message).join(", ")
    );
  }

  if (errors.length > 0) {
    throw new ManifestError(errors);
  }

  return data;
}
