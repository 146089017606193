export function obfuscate(value: string, pw: string): string {
  return value
    .split("")
    .map((char, i) =>
      String.fromCharCode(char.charCodeAt(0) + pw.charCodeAt(i % pw.length))
    )
    .reverse()
    .join("");
}

export function deobfuscate(value: string, pw: string): string {
  return value
    .split("")
    .reverse()
    .map((char, i) =>
      String.fromCharCode(char.charCodeAt(0) - pw.charCodeAt(i % pw.length))
    )
    .join("");
}
