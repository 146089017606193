export enum GameCommand {
  // User triggered game actions
  GO_TO_LEVEL = "go-to-level",
  COLLECT_ITEM = "collect-item",
  COLLECT_KEY = "collect-key",
  CONSUME_CLUE = "consume-clue",
  SET_VAR = "set-var",
  ADD_SCORE = "add-score",
  ADD_PENALTY = "add-penalty",
  VISIT_URL = "visit-url",
  CANCEL_GAME = "cancel-game",

  // Outcomes from user actions. Backend driven
  TRIGGER_ELEMENT = "trigger-element",
  REQUEST_CLUE = "request-clue",
  FINISH_GAME = "finish-game",
  TIME_BONUS = "time-bonus",
  ARCHIVE_GAME = "archive-game",
  RESTART_GAME = "restart-game",
  INIT_GAME = "init-game",
  INIT_TIMER = "init-timer",
  RESOLVE_OK = "resolve-ok",
  RESOLVE_FAIL = "resolve-fail",
  EXPIRED = "expired",

  ERROR = "error",
}

// Legacy
export interface GameActionPayloadDeprecated {
  action: GameCommand;
  data: string;
}
