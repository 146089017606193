import { GameErrorCode } from "../consts";
import { GameStateInterface } from "../types";

export class GameError extends Error {
  code: GameErrorCode;
  gameState?: GameStateInterface;
  origin?: Error;
  details?: string;

  constructor(
    error: GameErrorCode,
    details: string,
    origin?: Error,
    gameState?: GameStateInterface
  ) {
    super(`Error ${error.toFixed().padStart(3, "0")}`);
    this.name = "GameError";
    this.origin = origin;
    this.code = error;
    this.details = details;
    this.gameState = gameState;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, GameError.prototype);
  }
}
