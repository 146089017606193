import { compileExpression } from "filtrex";

const extraFunctions = {};

function mapUndefinedToEmptyString<T extends Record<string, unknown>>(
  data: T
): T {
  return new Proxy(data, {
    get(target, prop): unknown {
      if (prop === "__isProxy__") {
        return true;
      }

      return prop in target ? target[prop as keyof T] : "";
    },

    getOwnPropertyDescriptor(target, prop): PropertyDescriptor | undefined {
      return prop in target
        ? Object.getOwnPropertyDescriptor(target, prop)
        : { enumerable: false, configurable: true, writable: true, value: "" };
    },
  });
}

/**
 * Evaluates an expression with the given context.
 *
 * @param expression The expression to evaluate.
 * @param context The context to evaluate the expression in.
 * @returns The result of the expression.
 */
export function evaluateExpression<T = unknown>(
  expression: string,
  context: Record<string, unknown> = {}
): T {
  // Setup a proxy for the context (if it's not already a proxy).
  // This avoids "undefined" values, which has issues with Filtrex evaluation.
  const contextProxy = context.__isProxy__
    ? context
    : mapUndefinedToEmptyString(context);

  const checker = compileExpression(expression, extraFunctions);
  return checker(contextProxy) as T;
}
