import { ApiRestError } from "./ApiRestError";
import { ListDto } from "./dtos";
import autobind from "autobind-decorator";
import isArray from "lodash/isArray";

interface RoutesMap {
  [key: string]: unknown;
}

interface PathWithParams<Path> {
  path: Path;
  params?: Record<string, string | number>;
  search?: Record<
    string,
    string | number | boolean | string[] | number[] | boolean[] | undefined
  >;
}

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type PathWithMaybeParams<Path> = Path | PathWithParams<Path>;

/**
 * A generic REST API client that can be used to make requests to a REST API.
 *
 * @type Routes - A map of routes to their expected response types.
 */
@autobind
export abstract class ApiRestClient<Routes extends RoutesMap> {
  protected baseUrl: string;

  protected token: string | null = null;

  /**
   * @param baseUrl - The base URL of the API.
   * @example
   * ```ts
   * const api = new RestApiClient("https://api.example.com");
   * ```
   */
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl.replace(/\/$/, "");
  }

  /**
   * Parses the URL and replaces any parameters with their values.
   *
   * @param url - The URL to parse. If an object is provided, the `path` property will be used as the URL and the `params` property will be used to replace any parameters in the URL.
   * @returns The parsed URL.
   */
  protected parseParams(url: PathWithMaybeParams<keyof Routes>): string {
    if (typeof url !== "object") {
      return url.toString();
    }

    const { path, params, search } = url;

    let parsedPath = path.toString();

    for (const key in params) {
      parsedPath = parsedPath.replace(`:${key}`, params[key].toString());
    }

    if (search) {
      const searchParams = new URLSearchParams();

      for (const key in search) {
        if (isArray(search[key])) {
          (search[key] as string[]).forEach((value) => {
            searchParams.append(key, value);
          });
        } else if (search[key] !== undefined) {
          searchParams.append(key, String(search[key]));
        }
      }

      parsedPath += `?${searchParams.toString()}`;
    }

    return parsedPath;
  }

  /**
   * Parses the response into JSON.
   *
   * @param response
   * @returns
   */
  protected async getResponseJson<Out>(response: Response): Promise<Out> {
    if (response.status === 204) {
      return undefined as unknown as Out;
    }

    return await response.json();
  }

  /**
   * Makes a request to the API.
   *
   * @param url
   * @param method
   * @param body
   * @returns
   */
  protected async doRequest<In>(
    url: PathWithMaybeParams<keyof Routes>,
    method: Method = "GET",
    data?: In
  ): Promise<Response> {
    const fullUrl = `${this.baseUrl}${this.parseParams(url)}`;

    const headers: Record<string, string> = {
      Authorization: this.token ? `Bearer ${this.token}` : "",
    };

    const body =
      data && (data instanceof FormData ? data : JSON.stringify(data));

    if (!(data instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(fullUrl, {
      method,
      headers,
      body,
    });

    if (!response.ok) {
      try {
        const error = await response.json();
        throw new ApiRestError(response, error.message);
      } catch (e) {
        // If the response is not JSON, just throw the response
        if (e instanceof SyntaxError) {
          throw new ApiRestError(response, response.statusText);
        }

        throw e;
      }
    }

    return response;
  }

  /**
   * Makes a GET request to the API.
   *
   * @param url - The URL to make the request to. If an object is provided, the `path` property will be used as the URL and the `params` property will be used to replace any parameters in the URL.
   * @returns The response from the API.
   * @example
   * ```ts
   * const user = await api.get({ "/users/:id", { id: 123 } });
   * ```
   */
  public async get<
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>): Promise<Out> {
    const response = await this.doRequest(url, "GET");
    return await this.getResponseJson(response);
  }

  /**
   * Makes a GET (list) request to the API.
   *
   * @param url - The URL to make the request to. If an object is provided, the `path` property will be used as the URL and the `params` property will be used to replace any parameters in the URL.
   * @returns The response from the API.
   * @example
   * ```ts
   * const user = await api.get({ "/users/:id", { id: 123 } });
   * ```
   */
  public async getList<
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>): Promise<ListDto<Out>> {
    const response = await this.doRequest(url, "GET");
    return await this.getResponseJson(response);
  }

  /**
   * Makes a POST request to the API.
   *
   * @param url
   * @param body
   * @returns
   */
  public async post<
    In = unknown,
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>, body: In): Promise<Out> {
    const response = await this.doRequest(url, "POST", body);
    return await this.getResponseJson(response);
  }

  /**
   * Makes a PUT request to the API.
   *
   * @param url
   * @param body
   * @returns
   */
  public async put<
    In = unknown,
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>, body: In): Promise<Out> {
    const response = await this.doRequest(url, "PUT", body);
    return await this.getResponseJson(response);
  }

  /**
   * Makes a DELETE request to the API.
   *
   * @param url
   */
  public async delete<Path extends keyof Routes = keyof Routes>(
    url: PathWithMaybeParams<Path>
  ): Promise<void> {
    await this.doRequest(url, "DELETE");
  }

  /**
   * Makes a PATCH request to the API.
   *
   * @param url
   * @param body
   * @returns
   */
  public async patch<
    In = unknown,
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>, body: In): Promise<Out> {
    const response = await this.doRequest(url, "PATCH", body);
    return await this.getResponseJson(response);
  }

  /**
   * Uploads a file to the API, using multipart form data.
   *
   * @param url
   * @param file
   * @returns
   */
  public async upload<
    Path extends keyof Routes = keyof Routes,
    Out extends Routes[Path] = Routes[Path],
  >(url: PathWithMaybeParams<Path>, file: File): Promise<Out> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.doRequest(url, "POST", formData);
    return await this.getResponseJson(response);
  }

  /**
   * Set the token to be used for authentication.
   *
   * @param token
   */
  public setToken(token: string | null): void {
    this.token = token;
  }
}
