import {
  DEFAULT_GAMEFLOW,
  DEFAULT_SCORING,
  DEFAULT_SCREEN,
  DEFAULT_THEME,
  DEFAULT_TIMER,
} from "./consts";
import {
  euid,
  findAllLayoutElements,
  findElement,
  renderElement,
} from "./helpers";

import {
  GameClue,
  GameDataInterface,
  GameElement,
  GameElementCategory,
  GameFlow,
  GameItem,
  GameLayerContent,
  GameScoring,
  GameScreenSettings,
  GameTheme,
  GameTimer,
  Translations,
} from "./types";

/**
 * The game definition data class. It contains all the data needed to run a game
 *
 * @class GameData
 * @implements {GameDataInterface}
 * @param {GameDataInterface} data The game data
 * @returns {GameDataInterface}
 */
export class GameData implements GameDataInterface {
  version: number;
  updatedAt: Date;
  theme: GameTheme;
  screen: GameScreenSettings;
  translations: Translations;
  gameflow: GameFlow;
  timer: GameTimer;
  scoring: GameScoring;
  levels: { [levelId: string]: GameLayerContent };
  overlays: { [overlayId: string]: GameLayerContent };
  clues: { [clueId: string]: GameClue };
  items: { [itemId: string]: GameItem };

  /**
   * Creates an instance of GameData. Sets some default values if not provided
   *
   * @param data The game data
   */
  constructor(data: GameDataInterface) {
    this.version = data.version;
    this.updatedAt = data.updatedAt;
    this.theme = data.theme || DEFAULT_THEME;
    this.screen = data.screen || DEFAULT_SCREEN;
    this.translations = data.translations || {};
    this.gameflow = data.gameflow || DEFAULT_GAMEFLOW;
    this.timer = data.timer || DEFAULT_TIMER;
    this.scoring = data.scoring || DEFAULT_SCORING;
    this.levels = data.levels || {};
    this.overlays = data.overlays || {};
    this.clues = data.clues || {};
    this.items = data.items || {};
  }

  /**
   * Is the level the start counter level?
   *
   * @param id
   * @returns
   */
  isStartCounterLevel(id: string): boolean {
    return this.timer.startLevelId === id;
  }

  /**
   * Is the level the finish (win) level?
   *
   * @param id
   * @returns
   */
  isWinLevel(id: string): boolean {
    return this.gameflow.finishLevelId === id;
  }

  /**
   * Is the level the finish (lose) level?
   *
   * @param id
   * @returns
   */
  isGameOverLevel(id: string): boolean {
    return this.gameflow.gameOverLevelId === id;
  }

  /**
   * Get the level data
   *
   * @param levelId The level id
   * @param r A salt for randomization of the ids
   * @returns The level data or null if not found
   */
  getRenderedLevelData(levelId: string, r?: string): GameLayerContent | null {
    const levelData = this.levels[levelId];

    if (!levelData) {
      return null;
    }

    return {
      ...levelData,
      layout: levelData.layout.map((element) =>
        renderElement(this, levelId, element, r)
      ),
    };
  }

  /**
   * Get the verlay data
   *
   * @param overlayId The overlay id
   * @param r A salt for randomization of the ids
   * @returns The overlay data or null if not found
   */
  getRenderedOverlayData(
    overlayId: string,
    r?: string
  ): GameLayerContent | null {
    const overlayData = this.overlays[overlayId];

    if (!overlayData) {
      return null;
    }

    return {
      ...overlayData,
      layout: overlayData?.layout.map((element) =>
        renderElement(this, overlayId, element, r)
      ),
    };
  }

  /**
   * Find an element either in the level or it's overlay, by its id
   *
   * @param levelId
   * @param elementId
   * @returns
   */
  findElementById(
    levelId: string,
    elementId: string,
    r?: string
  ): GameElement | undefined {
    const levelData = this.levels[levelId];
    const overlayData = levelData.overlay
      ? this.overlays[levelData.overlay]
      : undefined;

    const fullLayout: GameElement[] = [
      ...levelData.layout,
      ...(overlayData?.layout || []),
    ];

    return findElement(
      fullLayout,
      (element) => euid(element.id, r) === elementId
    );
  }

  /**
   * Find an item by its id
   *
   * @param itemId
   * @returns
   */
  findItemById(itemId: string): GameItem | undefined {
    return this.items[itemId];
  }

  /**
   * Return the overlay ID for the given level
   *
   * @param levelId
   * @returns
   */
  getOverlayByLevelId(levelId: string): string | undefined {
    return this.levels[levelId]?.overlay;
  }

  /**
   * Return all the level ids
   *
   * @returns
   */
  getAllLevelIds(): string[] {
    return Object.keys(this.levels);
  }

  /**
   * Return all the lock ids
   *
   * @returns
   */
  getAllLockIds(): string[] {
    if (!this.levels) return [];

    const lockIds = Object.values(this.levels).reduce((prev, level) => {
      const found = findAllLayoutElements(
        level.layout,
        (element) =>
          element.type === GameElementCategory.LOCK && !!element.lockId
      ).map((element) => element.lockId?.toString() as string);

      return [...prev, ...found];
    }, [] as string[]);

    return lockIds;
  }

  /**
   * Return all the item ids
   *
   * @returns
   */
  getAllItemIds(): string[] {
    return Object.keys(this.items);
  }

  /**
   * Return all the clue ids
   *
   * @returns
   */
  getAllClueIds(): string[] {
    return Object.keys(this.clues);
  }

  /**
   * Return all the overlay ids
   *
   * @returns
   */
  getAllOverlayIds(): string[] {
    return Object.keys(this.overlays);
  }
}
