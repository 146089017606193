import { type GameStateInterface } from "@shared/game-engine";
import { useEffect, useState } from "react";

export function useTimer(gameState?: GameStateInterface) {
  const [hasTimer, setHasTimer] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const hasTimer = !!gameState?.expiresAt;
    setHasTimer(hasTimer);

    function check() {
      const isExpired = gameState?.expiresAt
        ? new Date(gameState.expiresAt).getTime() <
          Date.now() - (gameState.timeOffset || 0)
        : false;

      setHasTimer(hasTimer);
      setIsExpired(isExpired);
    }

    check();

    const interval = setInterval(check, 1000);
    return () => clearInterval(interval);
  }, [gameState?.expiresAt, gameState?.timeOffset]);

  return { hasTimer, isExpired };
}
